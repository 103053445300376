<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-file-sign
      </v-icon> Project Main Contracts
    </h2>
    <v-btn
      v-if="showSyncButton"
      text
      outlined
      class="sync-btn"
      @click="syncMainContractsFromPartner()"
    >
      <v-icon
        color="#37474F"
        size="30"
        left
      >
        mdi-cloud-sync-outline
      </v-icon>
      <span class="sync-btn-txt"> Sync Autodesk </span>
    </v-btn>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          v-if="!accessCheck('project_member')"
          color="#FF3700"
          @click="showCreateDialog = true"
        >
          Add New
        </v-btn>
      </v-col>
      <hr><br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="partnerCheck()"
        class="info-text"
      >
        *Indicates from partner account
      </p>
      <v-data-table
        :headers="filteredHeaders"
        :items="projectMainContractsList"
        :search="search"
        disable-sort
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on,attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ item.name }}<span
                    v-if="item.database === false"
                    class="partner-project"
                  >*</span></span>
                </template>
                <span v-if="item.description !== null">Description: <br> {{ item.description }}</span>
                <span v-else>N/A</span>
              </v-tooltip>
            </td>
            <td>
              <font v-if="item.amount !== null">
                <span class="currency-style">{{ projectDetails.currency }}</span> {{ item.amount | formatAmount }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.amount_paid !== null">
                <span class="currency-style">{{ projectDetails.currency }}</span> {{ item.amount_paid }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>{{ item.start_date | convertToLocal }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on,attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ item.planned_completion_date | convertToLocal }}</span>
                </template>
                <span v-if="item.actual_completion_date !== null">Actual Completion Date: <br> {{ item.actual_completion_date | convertToLocal }}</span>
                <span v-else>Actual Completion Date: <br> N/A</span>
              </v-tooltip>
            </td>
            <td>
              <font
                v-if="item.status !== null"
                class="status-style"
                :color="getStatusColor(item.status)"
              >
                {{ item.status | statusFormat }}
              </font>
              <font
                v-else
                class="status-style"
              >
                N/A
              </font>
            </td>
            <td v-if="!accessCheck('project_member')">
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="editProjectMainContract(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td v-if="!accessCheck('project_member')">
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                :disabled="item.database === false"
                @click.stop
                @click="deleteProjectMainContract(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <create-new-main-contract
      v-if="showCreateDialog"
      @closed="showCreateDialog = false"
      @success="getProjectMainContractsList"
    />
    <edit-main-contract
      v-if="showEditDialog"
      :main-contract-id="mainContractId"
      @closed="showEditDialog = false"
      @success="getProjectMainContractsList"
    />
    <delete-dialog
      v-if="showDeleteDialog"
      :title="'Delete this project main contract ?'"
      :message="'Action will permanently remove the main contract from this project, Do you want to continue ?'"
      @closed="showEditDialog = false"
      @confirmed="confirmDelete"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="getMainContractsFromPartner"
    />
  </v-container>
</template>

  <script>
    import spinner from 'src/views/dashboard/component/SpinnerCentre';
    import CreateNewMainContract from './CreateNewMainContract.vue';
    import EditMainContract from './EditMainContract.vue';
    import Constants from 'src/constants';
    import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
    import global from 'src/mixins/global';
    import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
    import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
    import moment from 'moment';

    export default {
      name: 'MainContractsList',
      components: {
        'centre-spinner': spinner,
        'create-new-main-contract': CreateNewMainContract,
        'edit-main-contract': EditMainContract,
        'delete-dialog': DeleteDialog,
        'overlay-alert': OverlayAlert,
        'partner-auth-check': PartnerAuthCheck,
      },
      filters: {
        statusFormat (status) {
          if (status === 'active') {
            return 'Active*';
          } else if (status === 'pending') {
            return 'Pending';
          } else if (status === 'completed') {
            return 'Completed*';
          } else {
            return status;
          }
        },
        convertToLocal (date) {
          return moment(date).local().format('Do MMMM YYYY');
        },
        formatAmount (val) {
          return Number(val).toFixed(0);
        },
      },
      mixins: [global],
      data () {
        return {
          search: '',
          loading: false,
          headers: [
            { text: 'Name', align: 'start', value: 'name' },
            { text: 'Amount', value: 'amount' },
            { text: 'Amount Paid', value: 'amount_paid' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'Planned Completion Date', value: 'planned_completion_date' },
            { text: 'Status', value: 'status' },
            { text: 'Edit', value: 'edit' },
            { text: 'Delete', value: 'delete' },
          ],
          showCreateDialog: false,
          showEditDialog: false,
          showDeleteDialog: false,
          mainContractId: '',
          showProgress: false,
          processAlert: '',
        };
      },
      computed: {
        projectMainContractsList () {
          return this.$store.getters['projects/getProjectMainContracts'];
        },
        projectDetails () {
          return this.$store.getters['projects/getProjectDetails'];
        },
        showSyncButton () {
          if (this.projectDetails.database === false) {
            return true;
          }
          return false;
        },
        filteredHeaders () {
      if (this.accessCheck('project_member')) {
        return this.headers.filter((item) => item.text !== 'Edit' && item.text !== 'Delete');
      } else {
        return this.headers;
      }
    },
      },
      async mounted () {
        await this.getProjectMainContractsList();
      },
      methods: {
        async getProjectMainContractsList () {
          this.loading = true;
          await this.$store.dispatch('projects/fetchProjectMainContracts', {
            projectId: this.$route.params.projectId,
          }).catch(() => {
            this.loading = false;
          });
          this.loading = false;
        },
        syncMainContractsFromPartner () {
          this.$refs.partnerAuth.isPartnerAuthorized();
        },
        async getMainContractsFromPartner () {
          this.showProgress = true;
          this.processAlert = 'Syncing...';
          await this.$store.dispatch('partners/fetchProjectMainContractsFromPartner', {
            projectId: this.$route.params.projectId,
          }).then(response => {
            this.showProgress = false;
            this.getProjectMainContractsList();
          }).catch(() => {
            this.showProgress = false;
          });
        },
        editProjectMainContract (id) {
          this.mainContractId = id;
          this.showEditDialog = true;
        },
        deleteProjectMainContract (id) {
          this.mainContractId = id;
          this.showDeleteDialog = true;
        },
        async confirmDelete () {
          this.loading = true;
          this.showDeleteDialog = false;
          await this.$store.dispatch('projects/deleteProjectMainContract', {
            mainContractId: this.mainContractId,
          }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Project main contract deleted successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.loading = false;
            this.getProjectMainContractsList();
          }).catch(() => {
            this.loading = false;
          });
        },
        getStatusColor (status) {
          if (status === 'active') {
            return '#2E7D32';
          } else if (status === 'pending') {
            return '#C62828';
          } else if (status === 'completed') {
            return '#FF3700';
          } else {
            return '#37474F';
          }
        },
      },
    };
  </script>

  <style scoped>
  .title-style{
    color: #37474F;
  }
  .icon-style{
    color: #37474F;
    font-size: 45px;
  }
  .v-data-table { background-color: #ECEFF1; }
  .v-data-table::v-deep td {
    font-size: 13px !important;
    color: #37474F !important;
  }
  .v-data-table::v-deep th {
    font-size: 12px !important;
    color: #37474F !important;
    font-weight: bold !important;
  }
  .sync-btn-txt {
  color: #FF3700;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-top: 10px;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.status-style{
  font-weight: bold;
  text-transform: capitalize;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
}
</style>
