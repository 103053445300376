<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            v-if="isMembersModuleExist"
            link
            @click="getProjectMembers()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-account-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Members
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isScheduleModuleExist"
            link
            @click="getProjectSchedulingChart()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-chart-box-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Schedules
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isCompaniesModuleExist"
            link
            @click="getProjectCompanies()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-domain
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Companies
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isBudgetModuleExist"
            link
            @click="getAllBudgets()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-document-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Budgets
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isMainContractsModuleExist"
            link
            @click="getProjectMainContracts()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-sign
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Main Contracts
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isContractModuleExist"
            link
            @click="getProjectContracts()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-account-hard-hat-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Contracts
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isIssueModuleExist"
            link
            @click="getProjectIssues()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-alert-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Project Issues
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isPTModuleExist"
            link
            @click="getPerformanceTrackingItems()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-chart-box-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Performance Tracking
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <p class="project-header">
      Project Name:- <font
        class="project-name"
      >
        {{ projectDetails.name }}
      </font>
    </p>
    <div class="project-progress-header">
      <p class="project-progress-title">
        Project Progress:-
        <v-progress-linear
          class="progress-bar-style"
          color="#37474F"
          height="20"
          striped
          dark
          rounded
          :value="projectDetails.project_progress"
        >
          <template>
            <strong
              v-if="projectDetails.project_progress !== null"
              class="percentage-style"
            >{{ Math.ceil(projectDetails.project_progress) }}%</strong>
            <strong v-else>0%</strong>
          </template>
        </v-progress-linear>
      </p>
    </div>
    <br>
    <br>
    <hr>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  class="drawer-style"
                >
                  mdi-checkbox-multiple-blank-outline
                </v-icon>
              </v-list-item-icon>
              Project Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            v-if="isMembersModuleExist"
            link
            @click="getProjectMembers()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-account-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Members
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isScheduleModuleExist"
            link
            @click="getProjectSchedulingChart()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-chart-box-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Schedules
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isCompaniesModuleExist"
            link
            @click="getProjectCompanies()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-domain
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Companies
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isBudgetModuleExist"
            link
            @click="getAllBudgets()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-document-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Budgets
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isMainContractsModuleExist"
            link
            @click="getProjectMainContracts()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-sign
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Main Contracts
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isContractModuleExist"
            link
            @click="getProjectContracts()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-account-hard-hat-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Contracts
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isIssueModuleExist"
            link
            @click="getProjectIssues()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-alert-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Project Issues
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isPTModuleExist"
            link
            @click="getPerformanceTrackingItems()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-chart-box-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Performance Tracking
            </v-list-item-title>
            <span
              v-if="(subscriptionCheck('trial'))"
              class="premium-container"
            >
              <v-icon class="premium-style">
                mdi-crown-circle
              </v-icon>
            </span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <budgets-list v-if="showBudgetsList" />
    <project-companies v-if="showProjectCompanies" />
    <project-issues-list v-if="showProjectIssues" />
    <contracts-list v-if="showContractsList" />
    <main-contracts-list v-if="showMainContractsList" />
    <performance-tracking-items
      v-if="showPerformanceTrackingItems"
      @show-budgets="getAllBudgets"
    />
    <project-members v-if="showProjectMembers" />
    <project-scheduling-chart v-if="showScheduleChart" />
  </v-container>
</template>

<script>
import BudgetsList from '././budgets/BudgetsList.vue';
import ProjectCompanies from './companies/ProjectCompanies.vue';
import ProjectIssuesList from './issues/ProjectIssuesList.vue';
import ContractsList from './contracts/ContractsList.vue';
import MainContractsList from './maincontracts/MainContractsList.vue';
import PerformanceTrackingItems from './performancetracking/PerformanceTrackingItems';
import ProjectMembers from './members/ProjectMembers.vue';
import ProjectSchedulingChart from './scheduling/ProjectSchedulingChart.vue';
import ModuleHelper from 'src/helpers/module-helper';
import global from 'src/mixins/global';
import Constants from 'src/constants';
  export default {
    name: 'ProjectSecondNavDrawer',
    components: {
      'budgets-list': BudgetsList,
      'project-companies': ProjectCompanies,
      'project-issues-list': ProjectIssuesList,
      'contracts-list': ContractsList,
      'main-contracts-list': MainContractsList,
      'performance-tracking-items': PerformanceTrackingItems,
      'project-members': ProjectMembers,
      'project-scheduling-chart': ProjectSchedulingChart,
    },
    mixins: [global],
    data: () => ({
      selectedItem: -1,
      showBudgetsList: false,
      showProjectCompanies: false,
      showProjectIssues: false,
      showContractsList: false,
      showMainContractsList: false,
      showPerformanceTrackingItems: false,
      showProjectMembers: true,
      showScheduleChart: false,
      helper: new ModuleHelper(),
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      projectSecondNavDrawerTab () {
        return this.$store.getters['projects/getProjectSecondNavDrawerTab'];
      },
      isPTModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_PERFORMANCE_TRACKING));
      },
      isBudgetModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_BUDGETS));
      },
      isContractModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_CONTRACTS));
      },
      isIssueModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_ISSUES));
      },
      isMainContractsModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_MAIN_CONTRACTS));
      },
      isScheduleModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_SCHEDULE));
      },
      isCompaniesModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_COMPANIES));
      },
      isMembersModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_MEMBERS));
      },
    },
    async mounted () {
      this.selectedItem = 0;
      this.checkForTab();
      await this.getProjectDetails();
    },
    methods: {
      checkForTab () {
        if (this.projectSecondNavDrawerTab === null) {
          this.getProjectMembers();
          this.selectedItem = 0;
        } else if (this.projectSecondNavDrawerTab === 0) {
          this.getProjectMembers();
          this.selectedItem = 0;
        } else if (this.projectSecondNavDrawerTab === 1) {
          this.getProjectSchedulingChart();
          this.selectedItem = 1;
        } else if (this.projectSecondNavDrawerTab === 2) {
          this.getProjectCompanies();
          this.selectedItem = 2;
        } else if (this.projectSecondNavDrawerTab === 3) {
          this.getAllBudgets();
          this.selectedItem = 3;
        } else if (this.projectSecondNavDrawerTab === 4) {
          this.getProjectMainContracts();
          this.selectedItem = 4;
        } else if (this.projectSecondNavDrawerTab === 5) {
          this.getProjectContracts();
          this.selectedItem = 5;
        } else if (this.projectSecondNavDrawerTab === 6) {
          this.getProjectIssues();
          this.selectedItem = 6;
        } else if (this.projectSecondNavDrawerTab === 7) {
          this.getPerformanceTrackingItems();
          this.selectedItem = 7;
        }
      },
      async getProjectDetails () {
        await this.$store.dispatch('projects/fetchProjectDetails', {
          projectId: this.$route.params.projectId,
        });
      },
      back () {
        this.$router.push({ name: 'Projects' });
      },
      getAllBudgets () {
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showBudgetsList = true;
        this.selectedItem = 3;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectCompanies () {
        this.showBudgetsList = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showProjectCompanies = true;
        this.selectedItem = 2;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectIssues () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showProjectIssues = true;
        this.selectedItem = 6;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectContracts () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showContractsList = true;
        this.selectedItem = 5;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectMainContracts () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showMainContractsList = true;
        this.selectedItem = 4;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getPerformanceTrackingItems () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showProjectMembers = false;
        this.showScheduleChart = false;
        this.showPerformanceTrackingItems = true;
        this.selectedItem = 7;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectMembers () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showScheduleChart = false;
        this.showProjectMembers = true;
        this.selectedItem = 1;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
      getProjectSchedulingChart () {
        this.showBudgetsList = false;
        this.showProjectCompanies = false;
        this.showProjectIssues = false;
        this.showContractsList = false;
        this.showMainContractsList = false;
        this.showPerformanceTrackingItems = false;
        this.showProjectMembers = false;
        this.showScheduleChart = true;
        this.selectedItem = 0;
        this.$store.dispatch('projects/clearStoredProjectDrawerTab');
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}
.drawer-style {
  font-size:25px;
  color:#4f4f37;
  font-weight:bold;
}
.premium-style {
  font-size:25px;
  color:#006883;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff;
  border-radius: 50%;
}
.drawer-item-style {
  font-size:14px !important;
  color:#37474F !important;
  font-weight:bold !important;
}
.project-header {
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: -20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
}
.project-progress-header{
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: -20px;
}
.project-progress-title{
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
  display: flex;
  gap: 20px;
}
.progress-bar-style{
  width: 40%;
  margin-top: 6px;
}
.percentage-style{
  font-size: 15px;
}
.project-name {
  color: #FF3700;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .project-header {
    margin-left: 20px;
  }
  .project-progress-title{
    display: block;
  }
  .progress-bar-style{
    width: 100%;
    margin-top: 8px;
  }
  .project-progress-header{
    margin-left: 20px;
  }
}
</style>
