<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-account-outline
        </v-icon>Add Member
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text class="text-body-1 text-center">
            <p class="my-p-style">
              Add New Project Member <br><br>
              <span class="wrk-hrs-sub">NB:- Project and all it's details will be visible to this member.</span>
            </p>
          </v-card-text>
          <br>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="8"
            >
              <v-autocomplete
                v-model="selectedMembers"
                class="field-style"
                :items="membersList"
                :rules="[(v) => !!v || 'This field is required']"
                :item-text="memberName"
                item-value="user.external_id"
                label="Members*"
                single-line
                append-icon="mdi-account-outline"
                outlined
                multiple
                dense
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="addProjectMember()"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';

  export default {
    name: 'AddProjectMember',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    data () {
      return {
        showModal: true,
        loading: false,
        valid: false,
        selectedMembers: [],
      };
    },
    computed: {
      projectMembers () {
        return this.$store.getters['projects/getProjectMembers'];
      },
      membersList () {
        const allMembers = this.$store.getters['members/getMembersList'];
        const filteredMembers = allMembers.filter(i => !this.projectMembers.some(j => i.user.external_id === j.external_id));
        return filteredMembers;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.roleCheck('userAdmin') && await this.getMembersList();
    },
    methods: {
      memberName (e) {
        return `${e.user.first_name} ${e.user.last_name}`;
      },
      async getMembersList () {
        this.loading = true;
        await this.$store.dispatch('members/fetchMembersList');
        this.loading = false;
      },
      async addProjectMember () {
        if (this.$refs.form.validate() === false || this.selectedMembers.length === 0) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please select atleast one user before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('projects/addProjectMember', {
          projectId: this.$route.params.projectId,
          details: {
            users: this.selectedMembers,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project members added successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('success');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },

    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #FF3700;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.wrk-hrs-sub {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.field-style {
  margin-top: -20px;
}
</style>
