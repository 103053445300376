<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <v-container>
        <br>
        <h2 class="title-style">
          <v-icon
            class="icon-style"
            left
          >
            mdi-file-document-edit
          </v-icon>Add Main Contract
        </h2>
        <br>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="mainContractName"
                  label="Name*"
                  :rules="[(v) => !!v || 'This feild is required']"
                  class="field-style"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="status"
                  class="field-style"
                  menu-props="auto"
                  label="Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  :items="statusList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  label="Description"
                  class="field-style"
                  filled
                  auto-grow
                  rows="2"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="startDialog"
                  v-model="startDateDialog"
                  :return-value.sync="startDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Start Date*"
                      :rules="[(v) => !!v || 'This field is required']"
                      class="field-style"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#37474F"
                    :min="minStartDate"
                    @input="$refs.startDialog.save(date)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="plannedDateDialog"
                  v-model="plannedEndDateDialog"
                  :return-value.sync="plannedEndDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="plannedEndDate"
                      label="End Date*"
                      :rules="[(v) => !!v || 'This field is required']"
                      class="field-style"
                      prepend-inner-icon="mdi-calendar"
                      hint="Planned completion date"
                      persistent-hint
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#37474F"
                    :min="minEndDate"
                    @input="$refs.plannedDateDialog.save(date)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="plannedEndDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="createProjectMainContract()"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';

  export default {
    name: 'CreateNewMainContract',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        valid: false,
        statusList: [
          { name: 'Active', id: 'active' },
          { name: 'Pending', id: 'pending' },
          { name: 'Completed', id: 'completed' },
        ],
        date: '',
        minStartDate: moment().format('YYYY-MM-DD'),
        loading: false,
        startDateDialog: false,
        plannedEndDateDialog: false,
        mainContractName: '',
        status: '',
        startDate: '',
        plannedEndDate: '',
        description: '',
      };
    },
    computed: {
      minEndDate () {
        return moment(this.startDate).format('YYYY-MM-DD');
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      async createProjectMainContract () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
            return;
        }
        this.loading = true;
        const projectId = this.$route.params.projectId;
        await this.$store.dispatch('projects/createProjectMainContract', {
          projectId: projectId,
          details: {
            name: this.mainContractName,
            description: this.description,
            start_date: this.startDate,
            planned_completion_date: this.plannedEndDate,
            status: this.status,
          },
        }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'New main contract created successfully to this project.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.loading = false;
            this.$emit('success');
            this.showModal = false;
          }).catch(() => {
            this.loading = false;
        });
      },
    },
  };
</script>

<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.field-style {
  margin-top: -10px;
}
</style>
