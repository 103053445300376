<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-document-edit-outline
        </v-icon>
        Update Project Issue
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="title"
                  dense
                  label="Title*"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  label="Description"
                  class="field-style"
                  filled
                  auto-grow
                  rows="2"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="startDialog"
                  v-model="startDateDialog"
                  :return-value.sync="startDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Start Date"
                      class="field-style"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#37474F"
                    :min="minStartDate"
                    @input="$refs.startDialog.save(date)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="endDialog"
                  v-model="endDateDialog"
                  :return-value.sync="endDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="End Date"
                      class="field-style"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    scrollable
                    color="#37474F"
                    :min="minEndDate"
                    @input="$refs.endDialog.save(date)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="endDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="assignedTo"
                  class="field-style"
                  :items="projectCompanies"
                  item-text="company.name"
                  item-value="company.id"
                  label="Assigned To"
                  hint="Only this assigned company app users can handle this issue."
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="status"
                  class="field-style"
                  :items="statusList"
                  item-text="name"
                  item-value="id"
                  label="Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  single-line
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-switch
                  v-model="hrWork"
                  label="High Risk Work"
                  hint="Work involves risk"
                  persistent-hint
                  color="#FF3700"
                  class="hrw-switch"
                />
              </v-col>
            </v-row>
            <br>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="updateProjectIssueDetails()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import moment from 'moment';

  export default {
    name: 'EditProjectIssueDetails',
    components: {
      'centre-spinner': spinner,
    },
    props: {
      projectIssueDetails: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        loading: false,
        isFormValid: false,
        date: '',
        title: '',
        description: null,
        startDateDialog: false,
        endDateDialog: false,
        startDate: '',
        endDate: '',
        assignedTo: '',
        status: '',
        hrWork: false,
        minStartDate: moment().format('YYYY-MM-DD'),
        statusList: [
          { name: 'Pending', id: 'pending' },
          { name: 'Completed', id: 'completed' },
        ],
      };
    },
    computed: {
      minEndDate () {
        return moment(this.startDate).format('YYYY-MM-DD');
      },
      projectCompanies () {
        return this.$store.getters['projects/getProjectCompanies'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.loadOnMount();
      await this.getProjectCompanies();
      this.loading = false;
    },
    methods: {
      loadOnMount () {
        this.title = this.projectIssueDetails.title;
        this.description = this.projectIssueDetails.description;
        this.startDate = this.projectIssueDetails.start_date !== null ? this.convertStringToLocalDatetime(this.projectIssueDetails.start_date) : null;
        this.endDate = this.projectIssueDetails.due_date !== null ? this.convertStringToLocalDatetime(this.projectIssueDetails.due_date) : null;
        this.assignedTo = this.projectIssueDetails.company !== null ? this.projectIssueDetails.company.id : null;
        this.status = this.projectIssueDetails.status;
        this.hrWork = this.projectIssueDetails.high_risk_work;
      },
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('YYYY-MM-DD');
      },
      async getProjectCompanies () {
        await this.$store.dispatch('projects/fetchProjectCompanies', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
      },
      updateProjectIssueDetails () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
            return;
        }
        this.loading = true;
        this.$store.dispatch('projects/updateProjectIssueDetails', {
          projectIssueId: this.projectIssueDetails.id,
          details: {
            title: this.title,
            description: this.description,
            start_date: this.startDate,
            due_date: this.endDate,
            company_id: this.assignedTo,
            status: this.status,
            high_risk_work: this.hrWork,
          },
        }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Project issue details updated successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.$emit('success');
            this.loading = false;
            this.showModal = false;
          }).catch(() => {
            this.loading = false;
        });
      },
    },
  };
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #FF3700;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.my-p-style-sub {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
</style>
